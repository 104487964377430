/*oE (vsechny stranky)*/
$zahlavipozadi: #EFF8F4;
$zahlavitext: #272727;
$nazev: #204998;
$dodatek: #0078A5;
$zahlaviodkazy: #0078A5;
$vyhledavaniobrys: #1ACAF5;
 
$hlavninadpisy: #345DAC;
 
$menupozadi: #204998;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #24D4FF;
$menutlacitkatext: #000000;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #EFF8F4;
$upozorneninadpisy: #345DAC;
$upozornenitext: #000000;
$upozorneniodkazy: #204998;
 
$aktuality1nadpisy: #345DAC;
$linky: #24D4FF;
$bileplochyodkazy: #345DAC;
 
$deskalinky: #24D4FF;
 
$kalendarakcipozadi: #24D4FF;
$akcehlavninadpis: #204998;
$akcenadpisy: #204998;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #233845;
$odkazyTabulekAktualni: #EFF8F4;
$bunkyTabulekOstatni: #EFF8F4;
$bunkyTabulekAktualni: #345DAC;
$akcetlacitkapozadi: #345DAC;
$akcetlacitkatext: #FFFFFF;
$akceodkazy: #0C3584;
 
$formularepozadi: #EFF8F4;
$formularetext: #272727;
$formularenadpis: #345DAC;
$polepozadi: #FFFFFF;
$poletext: #272727;
$poleobrysy: #1ACAF5;
 
$tlacitkapozadi: #24D4FF;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #204998;
$vysokapatatext: #EFF8F4;
$vysokapataodkazy: #EFF8F4;
$vysokapatanadpisy: #24D4FF;
$vysokapatasymboly: #24D4FF;
 
$patapozadi: #EFF8F4;
$patatext: #272727;
$pataodkazy: #204998;
